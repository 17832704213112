<template>
    <div>
        <!-- ToDo: remove useless 'Main' element -->
        <Main>
            <Box
                :headerText="isLoading ? 'Lade Aufträge ...' : `Aufträge (${items.length})`"
                :isLoading="isLoading"
                noBody
                class="mb-5"
            >
                <template v-if="!isLoading">
                    <div v-if="!items.length" class="mx-3 mb-3">
                        Keine Aufträge.
                    </div>
                    <b-table-simple hover class="mb-0" tbody-tr-class="py-1">
                        <b-thead>
                            <b-tr>
                                <b-th @click="sort(2)" class="m-0 px-0 re-small-fontsize">
                                    Termin<b-img :src="getSortArrow(2)" class="sortArrow" />
                                </b-th>
                                <b-th @click="sort(0)" class="m-0 px-0 re-small-fontsize">
                                    Strasse<b-img :src="getSortArrow(0)" class="sortArrow" />
                                </b-th>
                                <b-th @click="sort(1)" class="m-0 px-0 re-small-fontsize">
                                    Auftrag<b-img :src="getSortArrow(1)" class="sortArrow" />
                                </b-th>
                                <b-th @click="sort(3)" class="m-0 px-0 re-small-fontsize">
                                    Status<b-img :src="getSortArrow(3)" class="sortArrow" />
                                </b-th>
                                <b-th @click="sort(4)" class="m-0 px-0 re-small-fontsize w-auto">
                                    Ausführbarkeit<b-img :src="getSortArrow(4)" class="sortArrow" />
                                </b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <!-- ToDo(refactor): this is a hacky way of iterating over items without using prop 'items' on b-table component -->
                            <template v-for="item in items">
                                <b-tr :key="item.auftragID">
                                    <b-td colspan="4">
                                        <div :class="!!item.executionDatePlanned ? '' : 'text-secondary'">
                                            <font-awesome-icon
                                                v-if="item.installationPlanStatusAmpel"
                                                class="mr-1"
                                                :class="getStatusVariant(item.installationPlanStatusAmpel)"
                                                :icon="['fas', 'circle']"
                                            />
                                            <template v-if="item.executionDatePlanned">
                                                {{ item.executionDatePlanned | formatDate(`${dateFormat} HH:mm`) }} Uhr
                                                <font-awesome-icon
                                                    v-if="item.executionDateAgreement !== null"
                                                    :icon="['fas', executionDatePlannedFaIcon(item)]"
                                                    class="mx-1"
                                                    :beat-fade="executionDatePlannedFaBeatFade(item)"
                                                />
                                            </template>
                                            <template v-else>
                                                {{ item.fromDate | formatDate(dateFormat) }} -
                                                {{ item.toDate | formatDate(dateFormat) }}
                                            </template>
                                        </div>
                                        <div v-if="item.showEditAddress">
                                            <font-awesome-icon class="re-icon" :icon="['fas', 'fa-map-marked-alt']" />
                                            {{ item.hak?.address }}, {{ item.hak?.city }}
                                        </div>
                                        <div v-else style="cursor: pointer" @click="onAddressClick(item)">
                                            <font-awesome-icon class="re-icon" :icon="['fas', 'fa-map-marked-alt']" />
                                            {{ item.hak?.address }}, {{ item.hak?.city }}
                                        </div>
                                        <div class="d-flex small text-secondary">
                                            <div class="re-icon-width">
                                                <font-awesome-icon :icon="['fas', 'stopwatch']" />
                                            </div>
                                            {{ formatRolloutSeconds(item.rolloutSeconds) }} Std. |
                                            <span class="ml-1">
                                                <font-awesome-icon
                                                    :icon="['fas', auftragStatusFaIcon(item.auftragStatusID)]"
                                                />
                                                {{ item.auftragStatusID.toUpperCase() | translateAuftragStatus }}
                                            </span>
                                            <span v-if="false" class="ml-1">
                                                <!-- ToDo: enable when business wants to start using this functionality (and #20331 has been implemented) -->
                                                |
                                                <font-awesome-icon
                                                    v-if="item?.realityCheckCompleted === true"
                                                    :icon="['fas', 'file-circle-check']"
                                                    size="lg"
                                                    title="SUCCESS: Prüfung 'Auftrag vs. Realität' erfolgreich durchgeführt"
                                                />
                                                <font-awesome-icon
                                                    v-else-if="item?.realityCheckCompleted === false"
                                                    :icon="['fas', 'file-circle-exclamation']"
                                                    size="lg"
                                                    title="FAILURE: Prüfung 'Auftrag vs. Realität' nicht(!) erfolgreich durchgeführt"
                                                />
                                                <font-awesome-icon
                                                    v-else
                                                    :icon="['fas', 'file-circle-question']"
                                                    size="lg"
                                                    title="'Prüfung Auftrag vs. Realität' (noch) nicht durchgeführt"
                                                />
                                            </span>
                                        </div>
                                        <div class="d-flex small text-secondary">
                                            <div class="re-icon-width">
                                                <font-awesome-icon :icon="['fas', 'tachograph-digital']" />
                                            </div>
                                            <!-- DZ - Direktzähler (ohne Wandler)" -->
                                            {{ item.directZaehler }} DZ
                                            <!-- breaker -->
                                            <template v-if="item.breakerZaehlerDavon">
                                                <b-badge class="mx-1" variant="warning">
                                                    {{ item.breakerZaehlerDavon }} Breaker
                                                </b-badge>
                                            </template>
                                            <!-- WZ - Wandlerzähler (mit Wandler) -->
                                            | {{ item.wandlerZaehler }} WZ
                                            <!-- GS - Gerätestandort -->
                                            | {{ item.geraetestandorte }} GS
                                            <span>
                                                <b-badge
                                                    class="ml-1"
                                                    :variant="commTech(item) === 'LTE' ? 'warning' : 'light'"
                                                >
                                                    {{ commTech(item) }}
                                                </b-badge>
                                            </span>
                                        </div>
                                        <template v-if="activeTabIndex !== 0">
                                            <div class="d-flex small text-secondary">
                                                <div class="re-icon-width">
                                                    <font-awesome-icon :icon="['fas', 'user-tie']" />
                                                </div>
                                                <template v-if="item?.manager?.userID">
                                                    {{ item.manager.fullName }} - {{ item.manager?.company?.name }}
                                                </template>
                                                <template v-else>
                                                    Kein Leiter zugewiesen
                                                </template>
                                            </div>
                                            <div class="d-flex small text-secondary">
                                                <div class="re-icon-width">
                                                    <font-awesome-icon :icon="['fas', 'user']" class="mr-1" />
                                                </div>
                                                <template v-if="item?.technician?.userID === null">
                                                    Kein Techniker zugewiesen
                                                </template>
                                                <template v-else-if="currentUser?.userID === item?.technician?.userID">
                                                    Mir zugewiesen
                                                </template>
                                                <template v-else>
                                                    {{ item?.technician?.fullName }}
                                                </template>
                                            </div>
                                        </template>
                                        <div class="d-flex small text-secondary">
                                            <div class="re-icon-width">
                                                <font-awesome-icon :icon="['fas', 'house-chimney-crack']" />
                                            </div>
                                            {{ item.hak?.tp }}
                                            <span
                                                v-b-tooltip.hover
                                                class="ml-1"
                                                :title="
                                                    `status: ${item?.auftragStatusID.toUpperCase()} ('${
                                                        item.auftragStatus?.label
                                                    }')`
                                                "
                                            >
                                                <font-awesome-icon :icon="['fas', 'hashtag']" />{{ item.auftragID }}
                                            </span>
                                        </div>
                                    </b-td>
                                    <b-td class="text-right align-middle">
                                        <b-button
                                            :disabled="
                                                !userIsAllowedToClickButton(
                                                    item?.technician.userID,
                                                    item.auftragStatusID
                                                )
                                            "
                                            class="min-icon-button-width"
                                            variant="primary"
                                            @click="onClickOrder(item)"
                                        >
                                            <font-awesome-icon
                                                v-if="item.auftragStatusID === 'creating'"
                                                :icon="['fas', 'hourglass-half']"
                                                size="lg"
                                                spin
                                            />
                                            <font-awesome-icon
                                                v-else-if="item.auftragStatusID === 'error'"
                                                :icon="['fas', 'triangle-exclamation']"
                                                size="lg"
                                                beat-fade
                                            />
                                            <font-awesome-icon
                                                v-else-if="item.auftragStatusID === 'assigned'"
                                                :icon="['fas', 'user']"
                                                size="lg"
                                            />
                                            <font-awesome-icon
                                                v-else-if="item.auftragStatusID === 'accepted'"
                                                :icon="['fas', 'user-check']"
                                                size="lg"
                                            />
                                            <font-awesome-icon
                                                v-else-if="
                                                    ['new', 'created', 'in progress'].includes(item.auftragStatusID)
                                                "
                                                :icon="['fas', 'play-circle']"
                                                size="lg"
                                            />
                                            <font-awesome-icon
                                                v-else
                                                :icon="['fas', 'circle-question']"
                                                size="lg"
                                                beat-fade
                                                title="Auftragsstatus unbekannt"
                                            />
                                        </b-button>
                                        <b-button @click="onEditClick(item)" class="d-block ml-auto mt-1" size="sm">
                                            <font-awesome-icon :icon="['fas', 'pencil-alt']" />
                                        </b-button>
                                    </b-td>
                                </b-tr>
                                <b-tr v-if="!item.showEditAddress && item.showMap" :key="item.auftragID + '_map'">
                                    <b-td colspan="5">
                                        <l-map
                                            style="height: 300px; width: 100%; z-index: 0"
                                            :zoom="18"
                                            @ready="onReady"
                                            @locationfound="onLocationFound"
                                            :center="[item?.hak?.latitude, item?.hak?.longitude]"
                                            :maxZoom="18"
                                            :minZoom="5"
                                            ref="map"
                                        >
                                            <l-tile-layer :url="url" :attribution="attribution" />
                                            <l-circle-marker
                                                :lat-lng="[item?.hak?.latitude, item?.hak?.longitude]"
                                                :radius="10"
                                                color="red"
                                            />
                                        </l-map>
                                    </b-td>
                                </b-tr>
                                <!-- ToDo(clarify): a modal would be the simpler solution -->
                                <b-tr v-if="item.showEditAddress" :key="item.auftragID + '_edit-address'">
                                    <b-td colspan="5">
                                        <b-card body-class="p-2">
                                            <div>Ausführungszeitraum gemäss Planung:</div>
                                            <div>
                                                {{ item.fromDate | formatDate(dateFormat) }} -
                                                {{ item.toDate | formatDate(dateFormat) }}
                                            </div>
                                            <b-card body-class="p-2">
                                                <b-card-text>
                                                    <div>Neuer Ausführungstermin:</div>
                                                    <b-form-datepicker
                                                        v-model="item.executionDatePlannedNew.date"
                                                        class="mb-2"
                                                        locale="de"
                                                    />
                                                    <b-form-timepicker
                                                        v-model="item.executionDatePlannedNew.time"
                                                        locale="de"
                                                        label-close-button="Schliessen"
                                                    />
                                                    <b-form-radio-group
                                                        class="mt-3"
                                                        stacked
                                                        v-model="item.executionDateAgreementNew"
                                                    >
                                                        <b-form-radio
                                                            value="0"
                                                            @change="onClickExecutionDateAgreement(item, 0)"
                                                        >
                                                            <div>Termin mit Kunde vereinbart</div>
                                                            <div class="small text-secondary">
                                                                (Die Uhrzeit wird mit einem * markiert.)
                                                            </div>
                                                        </b-form-radio>
                                                        <b-form-radio
                                                            value="1"
                                                            @change="onClickExecutionDateAgreement(item, 1)"
                                                        >
                                                            Termin selbst festgelegt
                                                        </b-form-radio>
                                                    </b-form-radio-group>
                                                    <div class="text-center mt-3">
                                                        <b-button
                                                            class="btn-secondary mr-3"
                                                            @click="onClickSaveExecutionDateNew(item)"
                                                        >
                                                            Speichern
                                                        </b-button>
                                                        <b-button
                                                            class="btn-secondary mr-3"
                                                            @click="onClickCancelExecutionDateNew(item)"
                                                        >
                                                            Abbrechen
                                                        </b-button>
                                                        <b-button
                                                            class="mr-3"
                                                            variant="primary"
                                                            @click="onClickRemoveExecutionDate(item)"
                                                        >
                                                            <font-awesome-icon :icon="['fas', 'trash']" size="lg" />
                                                        </b-button>
                                                    </div>
                                                </b-card-text>
                                            </b-card>
                                        </b-card>
                                    </b-td>
                                </b-tr>
                            </template>
                        </b-tbody>
                    </b-table-simple>
                </template>
            </Box>
        </Main>
        <DxTabs
            class="container"
            :selected-index="activeTabIndex"
            :data-source="dataSource"
            :disabled="isLoading || isPopupOpen"
            @item-click="onItemClick"
            @item-rendered="onItemRendered"
        />
    </div>
</template>

<script>
import moment from "moment";
import "moment/locale/de";
import _ from "lodash";

import DxTabs from "devextreme-vue/tabs";

import constants from "@/constants/constants";
import auftragsAPI from "@/services/api/auftrags.api";
import auftragAusfuehrungApi from "@/services/api/rolloutview/auftragAusfuehrung.api";
import "leaflet/dist/leaflet.css";
import { mapGetters } from "vuex";
import store from "@/store";
import userCompanyPermissionService from "@/services/userCompanyPermissionService";

import { LCircleMarker, LMap, LTileLayer } from "vue2-leaflet";

const DATE_FORMAT_INPUT = "YYYY-MM-DDTHH:mm:ss";

export default {
    name: "ExecutionList",
    components: {
        LCircleMarker,
        LMap,
        LTileLayer,
        DxTabs,
    },
    data() {
        return {
            attribution:
                '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> | &copy; <a href="http://www.openstreetmap.org/about/">OpenStreetMap</a> | <a href="https://www.mapbox.com/map-feedback/#/-74.5/40/10">Improve this map</a>',
            dateFormat: "dd., DD.MM.YYYY",
            items: [],
            isLoading: true,
            lastSort: 2,
            isDesc: true,
            url:
                "https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWktcmVwb3dlciIsImEiOiJja2t6anF6dnUzMXB4MnFxbm16ejVyMXJlIn0.Wf_akGZUp0tghsSMYJRf4g",
            map: {},
            location: {},
            sortIconNeutral:
                "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e",
            sortIconAsc:
                "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e",
            sortIconDesc:
                "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e",
            orderInstallationPlanStatusDesc: [
                constants.installationPlanStatusAmpel.GREEN,
                constants.installationPlanStatusAmpel.YELLOW,
                constants.installationPlanStatusAmpel.RED,
                null,
            ],
            orderorderInstallationPlanStatusAsc: [
                constants.installationPlanStatusAmpel.RED,
                constants.installationPlanStatusAmpel.YELLOW,
                constants.installationPlanStatusAmpel.GREEN,
                null,
            ],
            activeTabIndex: 0,
            isPopupOpen: false,
            dataSource: [
                {
                    id: constants.execList.tabs.PERSONAL_ITEM_ID,
                    text: "Eigene",
                    icon: "user",
                },
                {
                    id: constants.execList.tabs.COMPANY_ITEM_ID,
                    text: "Firma nicht verfügbar",
                    icon: "group",
                    visible: false,
                },
                {
                    id: constants.execList.tabs.ADMIN_ITEM_ID,
                    text: "Alle",
                    icon: "group",
                    visible: false,
                },
            ],
        };
    },
    computed: {
        ...mapGetters(["currentUser"]),
        isUserAdmin() {
            return this.currentUser ? userCompanyPermissionService.isUserAdmin(this.currentUser.userRoles) : false;
        },
    },
    async mounted() {
        moment.locale("de");
        await this.loadOrdersByActiveTabIndex();
        if (this.isUserAdmin) {
            this.enableAdminView();
        }
        this.adjustCompanyTab();
    },
    beforeDestroy() {
        if (Object.keys(this.map).length > 0) {
            // run stopLocate only if map is initialized (to avoid error)
            this.map?.stopLocate();
        }
    },
    methods: {
        auftragStatusFaIcon(status) {
            switch (status.toUpperCase()) {
                case "ACCEPTED":
                    return "user-check";
                case "ASSIGNED":
                    return "user";
                case "CREATED":
                    return "rectangle-list";
                case "CREATING":
                    return "hourglass-half";
                case "ERROR":
                    return "triangle-exclamation";
                case "NEW":
                    return "calendar-days";
                case "IN PROGRESS":
                    return "play";
                default:
                    return "circle-question";
            }
        },
        commTech(data) {
            if (data.selectedCommunicationTechnology) {
                return data.selectedCommunicationTechnology;
            } else if (data.eaCommunicationTechnology) {
                return data.eaCommunicationTechnology;
            } else {
                return "-";
            }
        },
        enableAdminView() {
            var adminViewIndex = this.getIndexById(constants.execList.tabs.ADMIN_ITEM_ID);
            this.enableTabByIndex(adminViewIndex);
        },
        executionDatePlannedFaBeatFade(a) {
            const at = this.findAgreementTypeByValue(a.executionDateAgreement);
            switch (at?.value) {
                case undefined:
                case 0:
                    return true;
                default:
                    return false;
            }
        },
        executionDatePlannedFaIcon(a) {
            const at = this.findAgreementTypeByValue(a.executionDateAgreement);
            switch (at?.value) {
                case 0:
                    return "handshake";
                case 1:
                    return "helmet-safety";
                case 2:
                    return "robot";
                default:
                    // unhandled values
                    return "triangle-exclamation";
            }
        },
        findAgreementTypeByValue(value) {
            return constants.selectableExecutionDateAgreementTypes.find((at) => at.value === value);
        },
        formatRolloutSeconds(s) {
            switch (s) {
                case null:
                    return "Nicht verfügbar";
                default:
                    return new Date(s * 1000).toISOString().slice(11, 16);
            }
        },
        adjustCompanyTab() {
            var companyViewIndex = this.getIndexById(constants.execList.tabs.COMPANY_ITEM_ID);
            this.dataSource[companyViewIndex].text = this.currentUser?.company.name ?? "Nicht verfügbar";
            this.enableTabByIndex(companyViewIndex);
        },
        getIndexById(id) {
            return this.dataSource.findIndex((obj) => obj.id === id);
        },
        enableTabByIndex(index) {
            this.dataSource[index].visible = true;
        },
        onItemRendered(e) {
            // sets tab id (used for tab switching)
            e.itemElement.setAttribute("data-tab-id", e.itemData.id);
        },
        // ToDo: use onSelectionChanged instead of onItemClick
        //   -> https://js.devexpress.com/Vue/Documentation/ApiReference/UI_Components/dxTabs/Configuration/#onSelectionChanged
        //   note: that might also simplify collateral logic
        async onItemClick(e) {
            const itemIndex = e.itemData.id;
            const anyItemShowEditAddress = this.items.some((item) => item.showEditAddress);
            if (anyItemShowEditAddress) {
                const result = await this.openReloadModal(itemIndex === this.activeTabIndex);
                if (result) {
                    this.updateActiveTab(itemIndex);
                    await this.loadOrdersByActiveTabIndex();
                }
                this.changeTabClasses();
                this.isPopupOpen = false;
            } else {
                this.updateActiveTab(itemIndex);
                await this.loadOrdersByActiveTabIndex();
            }
        },
        updateActiveTab(index) {
            this.activeTabIndex = index;
            this.lastSort = 2;
            this.isDesc = true;
        },
        changeTabClasses() {
            this.$nextTick(() => {
                const tabs = this.$el.querySelectorAll(".dx-tab");

                tabs.forEach((tab) => {
                    const tabId = tab.getAttribute("data-tab-id");
                    if (tab.classList.contains("dx-tab-selected")) {
                        tab.classList.remove("dx-tab-selected");
                        tab.setAttribute("aria-selected", "false");
                    }
                    if (tabId === `${this.activeTabIndex}`) {
                        tab.classList.add("dx-tab-selected");
                        tab.setAttribute("aria-selected", "true");
                    }
                });
            });
        },
        async openReloadModal(sameTab) {
            this.isPopupOpen = true;
            const tabInput = sameTab ? "neu laden" : "wechseln";
            return this.$bvModal.msgBoxConfirm(
                `Es sind noch Aufträge in Bearbeitung. Wenn Sie den Tab ${tabInput}, verwerfen Sie alle gespeicherten Änderungen.`,
                {
                    title: `Tab ${tabInput}?`,
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: `${_.startCase(_.toLower(tabInput))}`,
                    cancelTitle: "Abbrechen",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                }
            );
        },
        async loadOrdersByActiveTabIndex() {
            let companyFilter = false;
            let personalFilter = false;
            switch (this.activeTabIndex) {
                case 0: // 0 --> personal
                    personalFilter = true;
                    break;
                case 1: // 1 --> company
                    companyFilter = true;
                    break;
            }
            await this.ordersLoad(companyFilter, personalFilter);
        },
        userIsAllowedToClickButton(technicianID, auftragStatusID) {
            let userIsAllowedToClickButton = false;
            switch (auftragStatusID) {
                case "assigned":
                    // only the user assigned to the order can click to accept it #18066
                    userIsAllowedToClickButton = store.getters.currentUser.userID === technicianID;
                    break;
                case "accepted":
                case "in progress":
                    // everyone can click (current behaviour is like this -> should be more restrictive)
                    userIsAllowedToClickButton = true;
                    break;
                default:
                    // handle all other cases
                    // note: this should be "false" but actually it is fulfilling the current behaviour of the app
                    userIsAllowedToClickButton = true;
            }
            return userIsAllowedToClickButton;
        },
        onAddressClick(item) {
            item.showMap = !item.showMap;
        },
        onEditClick(item) {
            // ToDo: refactor this ugly solution of handling this... -> use form validation
            const isSameDate =
                item.executionDatePlannedNew.date ===
                this.compDate(item.executionDatePlanned, DATE_FORMAT_INPUT, "YYYY-MM-DD");
            const isSameTime =
                item.executionDatePlannedNew.time ===
                this.compDate(item.executionDatePlanned, DATE_FORMAT_INPUT, "HH:mm:ss");
            const isSameAgreement = item.executionDateAgreement === item.executionDateAgreementNew;
            if (item.showEditAddress && (!isSameDate || !isSameTime || !isSameAgreement)) {
                this.$bvModal
                    .msgBoxConfirm(
                        "Sie haben die Änderungen noch nicht gespeichert. Wollen Sie die Änderungen verwerfen?",
                        {
                            title: "Änderungen verwerfen?",
                            size: "sm",
                            buttonSize: "sm",
                            okVariant: "danger",
                            okTitle: "Verfwerfen",
                            cancelTitle: "Abbrechen",
                            footerClass: "p-2",
                            hideHeaderClose: false,
                            centered: true,
                        }
                    )
                    .then((value) => {
                        if (value) {
                            item.showEditAddress = false;
                            item.executionDatePlannedNew.date = this.compDate(
                                item.executionDatePlanned,
                                DATE_FORMAT_INPUT,
                                "YYYY-MM-DD"
                            );
                            item.executionDatePlannedNew.time = this.compDate(
                                item.executionDatePlanned,
                                DATE_FORMAT_INPUT,
                                "HH:mm:ss"
                            );
                            item.executionDateAgreementNew = item.executionDateAgreement;
                        }
                    });
            } else {
                item.showEditAddress = !item.showEditAddress;
            }
        },
        compDate(dateString, formatInput = DATE_FORMAT_INPUT, formatOutput = "DD.MM.YYYY, HH:mm") {
            const mom = moment(dateString, formatInput);
            return mom.isValid() ? mom.format(formatOutput) : null;
        },
        onClickSaveExecutionDateNew(item) {
            this.$bvModal
                .msgBoxConfirm("Möchten Sie den Ausführungstermin speichern?", {
                    title: "Ausführungstermin speichern",
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: "Speichern",
                    cancelTitle: "Abbrechen",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        this.handleSaveOrder(item);
                    }
                });
        },
        handleSaveOrder(item) {
            if (
                !!item.executionDatePlannedNew.date &&
                !!item.executionDatePlannedNew.time &&
                item.executionDateAgreementNew !== null
            ) {
                const payload = [
                    {
                        op: "replace",
                        path: "/executionDatePlanned",
                        value: `${item.executionDatePlannedNew.date}T${item.executionDatePlannedNew.time}`,
                    },
                    {
                        op: "replace",
                        path: "/executionDateAgreement",
                        value: item.executionDateAgreementNew,
                    },
                ];
                auftragsAPI.patch(item.auftragID, payload).then((resp) => {
                    item.executionDatePlanned = resp.executionDatePlanned;
                    item.executionDatePlannedNew.date = this.compDate(
                        resp.executionDatePlanned,
                        DATE_FORMAT_INPUT,
                        "YYYY-MM-DD"
                    );
                    item.executionDatePlannedNew.time = this.compDate(
                        resp.executionDatePlanned,
                        DATE_FORMAT_INPUT,
                        "HH:mm:ss"
                    );
                    item.executionDateAgreement = resp.executionDateAgreement;
                });
                item.showEditAddress = false;
            } else {
                this.$bvToast.toast("Bitte erfassen Sie das Datum, die Uhrzeit sowie die Vereinbarungsart.", {
                    title: "Fehler",
                    variant: "danger",
                    toaster: "b-toaster-bottom-right",
                    noAutoHide: true,
                    appendToast: true,
                });
            }
        },
        onClickCancelExecutionDateNew(item) {
            this.$bvModal
                .msgBoxConfirm("Möchten Sie den Ausführungstermin wirklich verwerfen?", {
                    title: "Ausführungstermin verwerfen",
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: "Verwerfen",
                    cancelTitle: "Abbrechen",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        item.executionDatePlannedNew.date = this.compDate(
                            item.executionDatePlanned,
                            DATE_FORMAT_INPUT,
                            "YYYY-MM-DD"
                        );
                        item.executionDatePlannedNew.time = this.compDate(
                            item.executionDatePlanned,
                            DATE_FORMAT_INPUT,
                            "HH:mm:ss"
                        );
                        item.executionDateAgreementNew = item.executionDateAgreement;
                        item.showEditAddress = false;
                    }
                });
        },
        onClickRemoveExecutionDate(item) {
            this.$bvModal
                .msgBoxConfirm("Möchten Sie den Ausführungstermin wirklich löschen?", {
                    title: "Ausführungstermin löschen",
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: "Löschen",
                    cancelTitle: "Abbrechen",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        this.handleRemoveOrder(item);
                    }
                });
        },
        handleRemoveOrder(item) {
            const payload = [
                {
                    op: "remove",
                    path: "/executionDatePlanned",
                },
                {
                    op: "remove",
                    path: "/executionDateAgreement",
                },
            ];
            auftragsAPI.patch(item.auftragID, payload).then((resp) => {
                item.executionDatePlanned = resp.executionDatePlanned;
                item.executionDatePlannedNew.date = null;
                item.executionDatePlannedNew.time = null;
                item.executionDateAgreement = null;
                item.executionDateAgreementNew = null;
            });
            item.showEditAddress = false;
        },
        onClickExecutionDateAgreement(item, value) {
            item.executionDateAgreementNew = value;
        },
        onReady() {
            this.map = this.$refs["map"][0].mapObject;
            this.map.locate({ watch: true });
        },
        onLocationFound(l) {
            this.location = l;
        },
        sort(index) {
            if (this.lastSort === index) {
                this.isDesc = !this.isDesc;
            } else {
                this.isDesc = false;
            }
            this.lastSort = index;

            this.items = this.items.sort((a, b) => {
                switch (index) {
                    case 0:
                        if (this.isDesc) {
                            return b.hak?.address.localeCompare(a.hak?.address);
                        } else {
                            return a.hak?.address.localeCompare(b.hak?.address);
                        }
                    case 1:
                        if (this.isDesc) {
                            return b.auftragID - a.auftragID;
                        } else {
                            return a.auftragID - b.auftragID;
                        }
                    case 2:
                        if (a.executionDatePlanned) {
                            if (this.isDesc) {
                                return (
                                    new Date(b.executionDatePlanned).getTime() -
                                    new Date(a.executionDatePlanned).getTime()
                                );
                            } else {
                                return (
                                    new Date(a.executionDatePlanned).getTime() -
                                    new Date(b.executionDatePlanned).getTime()
                                );
                            }
                        } else {
                            if (this.isDesc) {
                                return new Date(b.fromDate).getTime() - new Date(a.fromDate).getTime();
                            } else {
                                return new Date(a.fromDate).getTime() - new Date(b.fromDate).getTime();
                            }
                        }
                    case 3:
                        if (this.isDesc) {
                            return b.auftragStatus?.auftragStatusID.localeCompare(a.hak?.address);
                        } else {
                            return a.auftragStatus?.auftragStatusID.localeCompare(b.hak?.address);
                        }
                    case 4:
                        if (this.isDesc) {
                            return (
                                this.orderInstallationPlanStatusDesc.indexOf(a.installationPlanStatusAmpel) -
                                this.orderInstallationPlanStatusDesc.indexOf(b.installationPlanStatusAmpel)
                            );
                        } else {
                            return (
                                this.orderorderInstallationPlanStatusAsc.indexOf(a.installationPlanStatusAmpel) -
                                this.orderorderInstallationPlanStatusAsc.indexOf(b.installationPlanStatusAmpel)
                            );
                        }
                    default:
                        break;
                }
            });
        },
        getSortArrow(index) {
            if (this.lastSort === index) {
                return this.isDesc ? this.sortIconDesc : this.sortIconAsc;
            } else {
                return this.sortIconNeutral;
            }
        },
        getStatusVariant(status) {
            switch (status) {
                case constants.installationPlanStatusAmpel.RED:
                    return "text-danger";
                case constants.installationPlanStatusAmpel.YELLOW:
                    return "text-warning";
                case constants.installationPlanStatusAmpel.GREEN:
                    return "text-success";
            }
        },
        onClickOrder(item) {
            const hasShowEditAddress = this.items.some((item) => item.showEditAddress);
            if (hasShowEditAddress) {
                const modalMessage =
                    "Es sind noch Aufträge in Bearbeitung. Wenn sie fortfahren verwerfen Sie alle Änderungen.";
                this.$bvModal
                    .msgBoxConfirm(modalMessage, {
                        title: "Auftrag öffnen?",
                        size: "sm",
                        buttonSize: "sm",
                        okVariant: "danger",
                        okTitle: "Fortfahren",
                        cancelTitle: "Abbrechen",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then((value) => {
                        if (value) {
                            this.handleItemAction(item);
                        }
                    });
            } else {
                this.handleItemAction(item);
            }
        },
        handleItemAction(item) {
            this.onClickOrderOpen(item.auftragID);
        },
        onClickOrderOpen(orderId) {
            this.$router.push({ name: "execution-single", params: { orderId: orderId.toString() } });
        },
        ordersLoad(excludeAllExceptOwnCompany = false, excludeAllExceptPersonals = false) {
            this.isLoading = true;
            return auftragAusfuehrungApi
                .get({
                    // get all statuses which are handled in the view
                    status__in: ["new", "creating", "error", "created", "assigned", "accepted", "in progress"].join(
                        ","
                    ),
                    // ToDo(backend/frontend): only get aufträge where Auftrag.ManagerID is not NULL #19454
                    // proposal:
                    //   managerID__isnull: false -> backend: consider param only if it is passed (AND condition)
                    excludeAllExceptOwnCompany: excludeAllExceptOwnCompany,
                    excludeAllExceptPersonals: excludeAllExceptPersonals,
                })
                .then((r) => {
                    const response = r.data;
                    response.map((i) => {
                        i.showMap = false;
                        i.showEditAddress = false;
                        i.executionDateAgreementNew = i.executionDateAgreement;
                        i.executionDatePlannedNew = {};
                        i.executionDatePlannedNew.date = this.compDate(
                            i.executionDatePlanned,
                            DATE_FORMAT_INPUT,
                            "YYYY-MM-DD"
                        );
                        i.executionDatePlannedNew.time = this.compDate(
                            i.executionDatePlanned,
                            DATE_FORMAT_INPUT,
                            "HH:mm:ss"
                        );
                    });
                    this.items = response;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
    },
};
</script>

<style scoped>
.min-icon-button-width {
    min-width: 55px;
}
.re-icon-width {
    width: 23px;
}
.sortArrow {
    width: 0.65rem;
    height: 1rem;
    margin-left: 2px;
    margin-right: 1px;
}
.re-small-fontsize {
    font-size: 0.8em;
}

.container {
    z-index: 99999;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0 0;
}
::v-deep .dx-item[aria-selected="true"] {
    background-color: rgb(255, 176, 172);
}
::v-deep .dx-item {
    border: 1px solid rgb(197, 197, 197);
    border-radius: 3px;
}
</style>
