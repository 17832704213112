<template>
    <DxDataGrid
        id="grid-container"
        ref="dataGridRef"
        :data-source="rawReads"
        :show-borders="true"
        :remote-operations="true"
        class="mt-4"
        :allowColumnResizing="true"
        :allowColumnReordering="true"
        :showRowLines="true"
        :rowAlternationEnabled="true"
        :columnAutoWidth="true"
    >
        <DxLoadPanel :visible="isLoading" :enabled="true" :showIndicator="true" :showPane="true"></DxLoadPanel>
        <DxHeaderFilter :visible="true" :search="{ enabled: true }">
            <DxSearch :enabled="true" :timeout="900" :editor-options="searchEditorOptions" mode="contains" />
        </DxHeaderFilter>
        <DxSearchPanel :visible="true" width="30vw"></DxSearchPanel>
        <DxPaging :page-size="20" />
        <DxPager
            :show-page-size-selector="true"
            :allowed-page-sizes="[20, 50, 100]"
            :show-navigation-buttons="true"
            :show-info="true"
        />
        <DxGroupPanel :visible="true" />
        <DxGrouping :auto-expand-all="false" />
        <DxToolbar>
            <DxItem name="groupPanel" />
            <DxItem location="after" widget="dxButton" :options="clearFilterButtonOptions" />
            <DxItem widget="dxButton" :options="reloadButtonOptions" />
            <DxItem name="searchPanel"></DxItem>
        </DxToolbar>

        <DxMasterDetail :enabled="true" template="detail-template" />
        <DxColumn data-field="unimodRawReadId" caption="ID" data-type="number" width="60" />
        <DxColumn
            data-field="creationDate"
            caption="Erstelldatum"
            data-type="date"
            sortOrder="desc"
            :format="'dd.MM.yyyy HH:mm'"
        />
        <DxColumn data-field="device.factoryNo" caption="Factory No" data-type="string" />
        <DxColumn data-field="device.internalNo" caption="Internal No" data-type="string" />
        <DxColumn data-field="devicePoolID" caption="Device Pool ID" data-type="number" />
        <DxColumn data-field="auftragID" caption="Auftrag ID" cell-template="auftrag-template" data-type="number" />
        <template #auftrag-template="{ data }">
            <div>
                <router-link
                    v-if="data.data.auftragID"
                    title="Auftrag Detail"
                    :to="{ name: 'order-details', params: { id: data.data.auftragID } }"
                >
                    {{ data.data.auftragID }}
                </router-link>
            </div>
        </template>
        <DxColumn data-field="detail" caption="Details" width="50%" minWidth="200" data-type="string" />
        <DxColumn data-field="status" data-type="string" alignment="center" cell-template="status-template" />
        <template #status-template="{ data }">
            <div class="badge" v-bind:class="getStatusClass(data.data.status)">{{ data.data.status }}</div>
        </template>
        <template #detail-template="{ data }">
            <div>
                <button
                    type="button"
                    class="btn btn-light float-right"
                    title="Kopieren"
                    @click="copyToClipboard(data.data.rawData)"
                >
                    <i class="fas fa-copy" />
                </button>
                <vue-json-pretty
                    :data="getRawReadJson(data.data.rawData)"
                    :showDoubleQuotes="false"
                    :showIcon="true"
                    :showLineNumber="true"
                    :showLength="true"
                />
            </div>
        </template>
    </DxDataGrid>
</template>

<script>
import constants from "@/constants/constants";
import { createStore } from "devextreme-aspnet-data-nojquery";
import moment from "moment/moment";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import { mapGetters } from "vuex";

import {
    DxColumn,
    DxDataGrid,
    DxGroupPanel,
    DxGrouping,
    DxHeaderFilter,
    DxItem,
    DxLoadPanel,
    DxMasterDetail,
    DxPager,
    DxPaging,
    DxSearch,
    DxSearchPanel,
    DxToolbar,
} from "devextreme-vue/data-grid";
import unimodRawReadsApi from "../../services/api/unimodRawReads.api";
import gridService from "../../services/gridBaseService";

export default {
    name: "RawReads",
    components: {
        DxDataGrid,
        DxColumn,
        DxGroupPanel,
        DxGrouping,
        DxHeaderFilter,
        DxItem,
        DxLoadPanel,
        DxMasterDetail,
        DxPager,
        DxPaging,
        DxSearch,
        DxSearchPanel,
        DxToolbar,
        VueJsonPretty,
    },
    data() {
        return {
            rawReads: [],
            searchEditorOptions: {
                placeholder: "Search value",
                mode: "text",
            },
            clearFilterButtonOptions: {
                icon: "fas fa-filter-circle-xmark",
                hint: "Filter löschen",
                onClick: () => {
                    this.$refs.dataGridRef.instance.clearFilter();
                },
            },
            reloadButtonOptions: {
                icon: "fas fa-rotate",
                hint: "Aktualisieren",
                onClick: () => {
                    this.$refs.dataGridRef.instance.refresh();
                },
            },
            isLoading: false,
            localeText: null,
            startFrom: moment().add(-180, "days"),
            constants: constants,
        };
    },
    async mounted() {
        this.isLoading = true;

        try {
            const remoteDataSource = createStore(
                gridService.getStoreConfig({
                    key: "unimodRawReadId",
                    loadUrl: unimodRawReadsApi.getRawReadsForGridUrl(),
                })
            );
            this.rawReads = remoteDataSource;
        } finally {
            this.isLoading = false;
        }
    },
    computed: {
        ...mapGetters(["currentUser"]),
        startFromFormatted() {
            return moment(this.startFrom).format(constants.DATE_FORMAT);
        },
    },
    methods: {
        getRawReadJson(jsonString) {
            try {
                return JSON.parse(jsonString);
            } catch {
                return jsonString;
            }
        },
        getStatusClass(status) {
            switch (status.toUpperCase()) {
                case "DONE":
                    return "badge-success";
                case "ERROR":
                    return "badge-danger";
                case "PROCESSING":
                    return "badge-primary";
                case "SKIP":
                    return "badge-secondary";
                default:
                    return "badge-info";
            }
        },
        async copyToClipboard(jsonString) {
            await navigator.clipboard.writeText(jsonString);
        },
    },
    watch: {
        async currentUser(val) {
            if (val && this.currentUser) {
                if (
                    !this.currentUser.userRoles.some((userRole) =>
                        [constants.ADMIN_ROLE_ID, constants.LEITER_ROLE_ID].includes(userRole.roleID)
                    )
                ) {
                    await this.$router.replace({ name: "unauthorized" });
                }
            }
        },
    },
};
</script>

<style scoped>
#grid-container {
    width: 100%;
    height: calc(100vh - 72px);
}
</style>
