export const translateAuftragStatus = (status) => {
    switch (status.toUpperCase()) {
        case "ACCEPTED":
            return "Akzeptiert";
        case "ASSIGNED":
            return "Zugewiesen";
        case "COMPLETED":
            return "erledigt";
        case "CREATED":
            return "erstellt";
        case "CREATING":
            return "in Erstellung";
        case "ERROR":
            return "Fehler";
        case "IN PROGRESS":
            return "in Ausführung";
        case "NEW":
            return "zu planen";
        default:
            return status;
    }
};
