<template>
    <div>
        <DxDrawer
            opened-state-mode="shrink"
            position="bottom"
            reveal-mode="expand"
            :opened="selectedItems.length > 0"
            :close-on-outside-click="false"
            template="createOrder"
            class="drawer-container"
        >
            <template #createOrder>
                <!-- Auftrag creation -->
                <div style="height: 180px; min-width: 700px">
                    <h4 class="mt-4">
                        {{
                            selectedItems?.length > 1
                                ? `${selectedItems.length} Aufträge erstellen`
                                : "Auftrag erstellen"
                        }}
                    </h4>
                    <b-card class="px-3 py-2 mb-4" role="group" no-body>
                        <DxValidationGroup ref="targetGroup">
                            <div class="d-flex">
                                <div class="mr-2">
                                    <label>Firma<span class="text-danger">*</span></label>
                                    <DxSelectBox
                                        class="select-control"
                                        v-model="auftragForm.companyID"
                                        :search-enabled="true"
                                        :data-source="companies"
                                        :input-attr="{ 'aria-label': 'Firma' }"
                                        :search-mode="'contains'"
                                        :search-expr="'name'"
                                        :search-timeout="200"
                                        :min-search-length="0"
                                        display-expr="name"
                                        value-expr="companyID"
                                        validation-message-mode="always"
                                        @value-changed="onChangeCompany"
                                    >
                                        <DxValidator>
                                            <DxRequiredRule message="Firma ist ein Pflichtfeld" />
                                        </DxValidator>
                                    </DxSelectBox>
                                </div>
                                <div>
                                    <label>Leiter</label>
                                    <DxSelectBox
                                        class="select-control"
                                        v-model="auftragForm.managerID"
                                        :search-enabled="true"
                                        :data-source="managers"
                                        :input-attr="{ 'aria-label': 'Leiter' }"
                                        :search-mode="'contains'"
                                        :search-expr="'fullName'"
                                        :search-timeout="200"
                                        :min-search-length="0"
                                        display-expr="fullName"
                                        value-expr="userID"
                                        validation-message-mode="always"
                                    >
                                    </DxSelectBox>
                                </div>
                                <div class="mx-2">
                                    <label>Zeitraum<span class="text-danger">*</span></label>
                                    <DxDateRangeBox
                                        v-model="auftragForm.dateRange"
                                        label-mode="hidden"
                                        :min="minDateFrom"
                                        :startDate="auftragForm.from"
                                        :endDate="auftragForm.to"
                                        styling-mode="outlined"
                                        class="date-picker-control"
                                        display-format="dd. MMMM yyyy"
                                        validation-message-mode="always"
                                    >
                                        <DxValidator>
                                            <DxRequiredRule message="Zeitraum ist ein Pflichtfeld" />
                                        </DxValidator>
                                    </DxDateRangeBox>
                                </div>
                                <div class="d-inline-flex ml-auto mt-auto">
                                    <b-button
                                        @click="onClickAuftragPlanungCreate"
                                        :disabled="createAuftragIsBusy"
                                        variant="primary"
                                        :title="
                                            selectedItems?.length > 1
                                                ? `${selectedItems.length} Aufträge erstellen`
                                                : 'Auftrag erstellen'
                                        "
                                    >
                                        Auftrag (Planung) erstellen
                                        <b-spinner v-if="createAuftragIsBusy" small />
                                    </b-button>
                                </div>
                            </div>
                        </DxValidationGroup>
                    </b-card>
                </div>
            </template>

            <DxDataGrid
                ref="datagrid"
                class="grid-container"
                :data-source="haksDataSource"
                :show-borders="true"
                :remote-operations="true"
                :allow-column-resizing="true"
                :allow-column-reordering="true"
                :allow-column-fixing="true"
                :show-row-lines="true"
                :show-column-lines="false"
                :row-alternation-enabled="true"
                :columnAutoWidth="true"
                :on-initialized="onInitialized"
                :filter-sync-enabled="true"
                :filter-value="filterValue"
                @selection-changed="onSelectionChanged"
                @editor-preparing="onEditorPreparing"
                @cell-prepared="onCellPrepared"
                @exporting="onExporting"
            >
                <DxLoadPanel :enabled="true" :showIndicator="true" :showPane="true" />
                <DxExport :enabled="true" :formats="['xlsx']" />
                <DxHeaderFilter :visible="true" :search="{ enabled: true }">
                    <DxSearch :enabled="true" :timeout="900" :editor-options="searchEditorOptions" mode="contains" />
                </DxHeaderFilter>
                <!-- <DxFilterRow :visible="true"></DxFilterRow> -->
                <DxColumnChooser :enabled="true" />
                <DxColumnFixing :enabled="true" />

                <DxSelection
                    select-all-mode="allPages"
                    :deferred="true"
                    show-check-boxes-mode="always"
                    mode="multiple"
                />
                <DxFilterPanel :visible="true" />
                <DxSearchPanel :visible="true" width="30vw" />
                <DxPaging :page-size="20" />
                <DxPager
                    :show-page-size-selector="true"
                    :allowed-page-sizes="[20, 50, 100, 1000]"
                    :show-navigation-buttons="true"
                    :show-info="true"
                />
                <DxGroupPanel :visible="true" />
                <DxGrouping :auto-expand-all="false" />
                <DxSummary>
                    <DxGroupItem column="hakId" summary-type="count" />
                </DxSummary>
                <DxToolbar>
                    <DxItem name="groupPanel" />
                    <DxItem location="after" widget="dxButton" :options="clearFilterButtonOptions" />
                    <DxItem widget="dxButton" :options="reloadButtonOptions" />
                    <DxItem :widget="undefined" cssClass="toolbar-separator" />
                    <DxItem name="exportButton" :options="exportButtonOptions" />
                    <DxItem :widget="undefined" cssClass="toolbar-separator" />
                    <DxItem name="columnChooserButton" />
                    <DxItem name="searchPanel" />
                </DxToolbar>

                <DxColumn data-field="hakId" caption="HAK ID" data-type="number" width="60" :visible="false" />
                <DxColumn caption="Hausanschluss (HA)" :fixed="true">
                    <DxColumn
                        data-field="tp"
                        caption="HA"
                        data-type="string"
                        :fixed="true"
                        cell-template="ha-id-template"
                    />
                    <DxColumn
                        caption="NG"
                        :calculate-cell-value="getNetzgebietFromTp"
                        data-type="string"
                        :allow-search="false"
                    />
                    <DxColumn data-field="netzebene" caption="NE" data-type="number" :allow-search="false" />
                    <DxColumn data-field="city" caption="Ort" data-type="string" />
                    <DxColumn data-field="address" caption="Adresse" data-type="string" />
                </DxColumn>
                <DxColumn caption="">
                    <DxColumn data-field="standort" caption="Lage" data-type="string" />
                    <!-- Preparation for #18481 -->
                    <!-- <DxColumn caption="Gemeinde" data-type="string" /> -->
                    <DxColumn caption="Karte" cell-template="ha-map-template" :allow-search="false" />
                    <DxColumn
                        data-field="rollouted"
                        caption="Gerolloutet"
                        data-type="string"
                        cell-template="rollouted-template"
                        :customize-text="convertToJaNeinTeilweise"
                        :allow-search="false"
                    />
                    <DxColumn data-field="ts" caption="TS" data-type="string" />
                    <DxColumn data-field="tsDescription" caption="TS Bezeichnung" data-type="string" />
                    <DxColumn data-field="vk" caption="VK" data-type="string" />
                    <DxColumn data-field="vkDescription" caption="VK Bezeichnung" data-type="string" />
                    <DxColumn data-field="tsVkPlc" caption="TS/VK PLC" data-type="string" :allow-search="false" />
                    <DxColumn
                        data-field="lwl"
                        caption="LWL"
                        data-type="string"
                        :customize-text="getLwlString"
                        :allow-search="false"
                    />
                    <DxColumn
                        data-field="eth"
                        caption="ETH"
                        data-type="string"
                        :customize-text="formatCapitalizedString"
                        :allow-search="false"
                    />
                    <DxColumn
                        data-field="backFreileitung"
                        caption="hinter Freileitung"
                        data-type="string"
                        :customize-text="formatCapitalizedString"
                        :allow-search="false"
                    />
                </DxColumn>
                <DxColumn caption="Kommunikationstechnologie">
                    <DxColumn
                        data-field="eaCommunicationTechnology"
                        caption="EasyAsset"
                        data-type="string"
                        :allow-search="false"
                    />
                    <DxColumn
                        data-field="selectedCommunicationTechnology"
                        caption="Übersteuert"
                        data-type="string"
                        :allow-search="false"
                    />
                </DxColumn>
                <DxColumn caption="Ausführung">
                    <DxColumn
                        caption="Ausführbarkeit"
                        data-field="installationPlanStatusAmpel"
                        data-type="string"
                        cell-template="status-template"
                        :allow-search="false"
                    >
                        <DxHeaderFilter :data-source="installationPlanStatusAmpelFilter" />
                    </DxColumn>
                    <DxColumn
                        data-field="nextAmplifyingNode"
                        caption="Netzverstärkender TP"
                        data-type="string"
                        :allow-search="false"
                    />
                    <DxColumn
                        :calculate-cell-value="getTypFromAmplifyingNode"
                        caption="Typ"
                        data-type="string"
                        :allow-search="false"
                    />
                    <DxColumn
                        data-field="distNextAmplifyingNode"
                        caption="Distanz [m]"
                        data-type="string"
                        :allow-search="false"
                    />
                    <DxColumn
                        data-field="rolloutSeconds"
                        caption="Dauer"
                        data-type="number"
                        :customize-text="getEstimatedInstallationTimeHours"
                        :allow-search="false"
                    />
                    <DxColumn
                        data-field="geraetestandorte"
                        caption="GST gesamt"
                        data-type="number"
                        :allow-search="false"
                    />
                    <DxColumn
                        data-field="directZaehler"
                        caption="Direktmess. Zähler"
                        data-type="number"
                        :allow-search="false"
                    />
                    <DxColumn
                        data-field="breakerZaehlerDavon"
                        caption="davon Breaker"
                        data-type="number"
                        :allow-search="false"
                    />
                    <DxColumn
                        data-field="wandlerZaehler"
                        caption="Wandlerzähler"
                        data-type="number"
                        :allow-search="false"
                    />
                    <DxColumn data-field="flexibilitaeten" caption="Flex" data-type="number" :allow-search="false" />
                    <DxColumn data-field="rEintern" caption="VK/TS/UW" data-type="string" :allow-search="false" />
                    <DxColumn
                        data-field="auftragID"
                        caption="Auftrag ID"
                        cell-template="auftrag-template"
                        data-type="number"
                        alignment="left"
                    />
                </DxColumn>

                <DxColumn caption="Infobrief">
                    <DxColumn
                        data-field="infobriefStatus"
                        caption="verschickt"
                        data-type="string"
                        :allow-search="false"
                    />
                    <DxColumn
                        data-field="infobriefVon"
                        caption="Zeitraum von"
                        data-type="string"
                        :allow-search="false"
                    />
                    <DxColumn
                        data-field="infobriefBis"
                        caption="Zeitraum bis"
                        data-type="string"
                        :allow-search="false"
                    />
                </DxColumn>
                <template #rollouted-template="{ data }">
                    <div>
                        <font-awesome-icon
                            v-if="data.data.rollouted === null"
                            :icon="['fas', 'circle-exclamation']"
                            class="mx-1 text-danger"
                        />
                        <font-awesome-icon
                            v-if="data.data.rollouted === 0"
                            :icon="['fas', 'circle']"
                            class="mx-1 text-black-20"
                        />
                        <font-awesome-icon
                            v-else-if="data.data.rollouted === 1"
                            :icon="['fas', 'circle-half-stroke']"
                            class="mx-1 text-warning"
                        />
                        <font-awesome-icon
                            v-else-if="data.data.rollouted === 2"
                            :icon="['fas', 'circle-check']"
                            class="mx-1 text-success"
                        />
                        <span>{{ convertToJaNeinTeilweise(data) }}</span>
                    </div>
                </template>
                <template #auftrag-template="{ data }">
                    <div>
                        <router-link
                            v-if="data.data.auftragID"
                            :title="'Auftrag Detail zu Auftrag ' + data.data.auftragID"
                            :to="{ name: 'order-details', params: { id: data.data.auftragID } }"
                        >
                            <i class="fas fa-file-alt"></i>
                            {{ data.data.auftragID }}
                        </router-link>
                    </div>
                </template>
                <template #ha-id-template="{ data }">
                    <router-link
                        v-if="data.data.hakId"
                        :title="'HAK Detail'"
                        :to="{ name: 'hak-details', params: { id: data.data.hakId } }"
                    >
                        {{ data.data.tp }}
                    </router-link>
                </template>
                <template #ha-map-template="{ data }">
                    <router-link
                        title="HAK auf der Karte anzeigen"
                        :to="{ name: 'hak-map', params: { id: data.data.hakId } }"
                    >
                        <i class="fas fa-map-marked-alt"></i>
                    </router-link>
                </template>
                <template #status-template="{ data }">
                    <font-awesome-icon
                        v-b-tooltip.hover
                        :title="'Status Installationsplan: ' + data?.data?.installationPlanStatusRaw"
                        v-if="data.data.installationPlanStatusAmpel"
                        :icon="['fas', 'circle']"
                        :class="getStatusVariant(data.data.installationPlanStatusAmpel)"
                    />
                </template>
            </DxDataGrid>
        </DxDrawer>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import DxDrawer from "devextreme-vue/drawer";
import DxDateRangeBox from "devextreme-vue/date-range-box";
import DxSelectBox from "devextreme-vue/select-box";
import DxValidationGroup from "devextreme-vue/validation-group";
import { DxValidator, DxRequiredRule } from "devextreme-vue/validator";
import {
    DxColumn,
    DxColumnChooser,
    DxColumnFixing,
    DxDataGrid,
    DxExport,
    DxFilterPanel,
    DxGroupPanel,
    DxGrouping,
    DxGroupItem,
    DxHeaderFilter,
    DxItem,
    DxLoadPanel,
    DxPager,
    DxPaging,
    DxSearch,
    DxSearchPanel,
    DxSelection,
    DxSummary,
    DxToolbar,
} from "devextreme-vue/data-grid";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import moment from "moment";

import constants from "@/constants/constants";
import gridService from "@/services/gridBaseService";

import companiesApi from "@/services/api/companies.api";
import processApi from "@/services/api/process.api";
import rolloutviewApi from "@/services/api/rolloutview.api";
import usersAPI from "@/services/api/users.api";

export default {
    name: "HaksList",
    components: {
        DxSelectBox,
        DxDateRangeBox,
        DxDataGrid,
        DxColumn,
        DxColumnChooser,
        DxColumnFixing,
        DxDrawer,
        DxExport,
        DxFilterPanel,
        DxGroupPanel,
        DxGrouping,
        DxGroupItem,
        DxHeaderFilter,
        DxItem,
        DxLoadPanel,
        DxPager,
        DxPaging,
        DxRequiredRule,
        DxSearch,
        DxSearchPanel,
        DxSelection,
        DxSummary,
        DxToolbar,
        DxValidator,
        DxValidationGroup,
    },
    data() {
        return {
            createAuftragIsBusy: false,
            searchEditorOptions: {
                placeholder: "Search value",
                mode: "text",
            },
            clearFilterButtonOptions: {
                icon: "fas fa-filter-circle-xmark",
                hint: "Filter löschen",
                onClick: () => {
                    this.datagrid.clearFilter();
                    this.filterValue = null;
                },
            },
            reloadButtonOptions: {
                icon: "fas fa-rotate",
                hint: "Aktualisieren",
                onClick: () => {
                    this.datagrid.refresh();
                },
            },
            exportButtonOptions: {
                icon: "fas fa-file-excel",
                hint: "Excel Export",
            },
            selectedItems: [],
            filterValue: null,
            haksDataSource: null,
            datagrid: null,
            minDateFrom: new Date(),
            companies: [],
            managers: [],
            auftragForm: {
                companyID: null,
                managerID: null,
                dateRange: [],
                from: null,
                to: null,
                validStartDate: null,
                validEndDate: null,
            },
            installationPlanStatusAmpelFilter: [
                {
                    text: "Rot",
                    value: constants.installationPlanStatusAmpel.RED,
                },
                {
                    text: "Gelb",
                    value: constants.installationPlanStatusAmpel.YELLOW,
                },
                {
                    text: "Grün",
                    value: constants.installationPlanStatusAmpel.GREEN,
                },
                {
                    text: "(Leerwerte)",
                    value: [["installationPlanStatusAmpel", "=", null], "or", ["installationPlanStatusAmpel", "=", ""]],
                },
            ],
        };
    },
    computed: {
        ...mapGetters(["storeHakIds", "storeHaks", "storeFilter"]),
        selectionFilter() {
            return [
                ["auftragID", "=", null],
                "and",
                ["rollouted", "=", "0"],
                "and",
                [
                    [["eaCommunicationTechnology", "<>", null], "and", ["eaCommunicationTechnology", "<>", ""]],
                    "or",
                    [
                        ["selectedCommunicationTechnology", "<>", null],
                        "and",
                        ["selectedCommunicationTechnology", "<>", ""],
                    ],
                ],
            ];
        },
        validationGroup: function() {
            return this.$refs["targetGroup"].instance;
        },
        dateFormat() {
            return constants.DATE_FORMAT_GRID;
        },
    },
    created() {
        this.getFilterFromStoreOrParams();
    },
    beforeMount() {
        companiesApi.get().then((resp) => (this.companies = resp.data));
    },
    beforeRouteLeave(to, from, next) {
        this.saveFilterToStore();
        next();
    },
    methods: {
        ...mapActions(["setHakIds", "setHaks", "setFilter"]),
        onInitialized(e) {
            this.datagrid = e.component;
            this.loadDataGrid();
        },
        isSelectable(item) {
            return (
                !item.auftragID &&
                item.rollouted == 0 &&
                (item.eaCommunicationTechnology || item.selectedCommunicationTechnology)
            );
        },
        onCellPrepared(e) {
            // hacky way to disable pointer events for rows that have no checkbox
            // "dx-command-select" is the class of the checkbox column and "dx-state-disabled dx-state-invisible" is the class of cells without checkboxes
            if (
                e.cellElement.className.includes("dx-command-select") &&
                e.cellElement?.children[0]?.className.includes("dx-state-disabled dx-state-invisible")
            ) {
                e.cellElement.style.pointerEvents = "none";
            }
        },
        onEditorPreparing(e) {
            // we only want to change the behavior of the selection
            if (e.type !== "selection") return;

            // if the row is not selectable, we want to disable the checkbox
            if (e.parentType === "dataRow" && e.row && !this.isSelectable(e.row.data)) {
                e.editorOptions.disabled = true;
                e.editorOptions.visible = false;
                e.editorOptions.onValueChanged = (eValueChanged) => {
                    // if the user tries to select a non-selectable row, we want to deselect it
                    if (eValueChanged.value === true) {
                        e.component.deselectRows([e.row.data.hakId]);
                    }
                };
            } else if (e.parentType === "headerRow") {
                // if the header is selected, we want to select all (possible) rows
                e.editorOptions.onInitialized = (eInitialized) => {
                    if (eInitialized.component) this.selectAllCheckBox = eInitialized.component;
                };
                e.editorOptions.onValueChanged = (eValueChanged) => {
                    if (
                        (eValueChanged.previousValue === false || eValueChanged.previousValue === undefined) &&
                        eValueChanged.value === true
                    ) {
                        const existingFilter = this.datagrid.getCombinedFilter();
                        if (existingFilter && existingFilter.length > 0) {
                            const newfilter = [existingFilter, "and", this.selectionFilter];
                            e.component.option("selectionFilter", newfilter);
                        } else {
                            e.component.option("selectionFilter", this.selectionFilter);
                        }
                        this.selectAllCheckBox.option("value", true);
                    } else if (eValueChanged.previousValue === true && eValueChanged.value === false) {
                        e.component.option("selectionFilter", ["hakId", "=", -1]);
                        e.component.deselectAll();
                    }
                };
            }
        },
        onExporting(e) {
            // Export the data to an Excel file
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet("HA");
            exportDataGrid({
                component: e.component,
                worksheet: worksheet,
                autoFilterEnabled: true,
            }).then(function() {
                workbook.xlsx.writeBuffer().then(function(buffer) {
                    const now = moment().format(constants.DATETIME_FORMAT_FILE);
                    saveAs(new Blob([buffer], { type: "application/octet-stream" }), `${now}_REtasks_HA.xlsx`);
                });
            });
        },
        calculatetSelectedItems() {
            this.datagrid.getSelectedRowsData().then((sel) => {
                sel = sel.filter((s) => this.isSelectable(s));
                this.selectedItems = sel;
                if (sel.length === 0) {
                    this.selectAllCheckBox.option("value", false);
                }
            });
        },
        onChangeCompany() {
            if (this.auftragForm.companyID) {
                usersAPI
                    .get({ companyID: this.auftragForm.companyID, roleID: constants.LEITER_ROLE_ID })
                    .then((resp) => (this.managers = resp.data));
            }
        },
        onClickAuftragPlanungCreate() {
            // validate form input
            const validationResult = this.validationGroup.validate();
            if (!validationResult.isValid) {
                return;
            }

            const tickedHAKs = this.selectedItems;
            const payload = [];
            this.createAuftragIsBusy = true;
            this.selectedItems.forEach((h) => {
                payload.push({
                    hakID: h.hakId,
                    auftragType: "Rollout",
                    fromDate: this.auftragForm.dateRange[0],
                    toDate: this.auftragForm.dateRange[1],
                    managerID: this.auftragForm.managerID,
                });
            });
            processApi
                .createAuftragPlan(payload)
                .then((resp) => {
                    // extract auftragID from completed promises
                    const auftragIdsString = resp.data.map((a) => a.auftragID).toString();
                    const msg_pluralized = tickedHAKs.length > 1 ? "Aufträgen" : "Auftrag";
                    this.$bvToast.toast(
                        `Die Erstellung von ${tickedHAKs.length} ROLLOUT ${msg_pluralized} (Planung) wurde gestartet.`,
                        {
                            title: "SUCCESS",
                            variant: "success",
                            toaster: "b-toaster-bottom-right",
                            appendToast: true,
                        }
                    );
                    return auftragIdsString;
                })
                .then(() => {
                    // give users a bit of time to read the SUCCESS toast before navigating to orders
                    setTimeout(() => {
                        //this.$router.push({ name: "orders-list", query: { auftragId: auftragIdsString } });
                        this.$router.push({ name: "auftrag-planung" });
                    }, 3000);
                })
                .finally(() => {
                    this.createAuftragIsBusy = false;
                });
        },
        saveFilterToStore() {
            // store filter for later use
            this.setFilter(this.datagrid.getCombinedFilter());
        },
        getFilterFromStoreOrParams() {
            try {
                // 1) check for hakids in params
                const paramHakIds = this.$route.params.hakIds;
                if (paramHakIds?.length > 0) {
                    // add hakids to filter
                    const hakIds = paramHakIds.split(",").map((id) => parseInt(id));
                    let filter = [];
                    hakIds.forEach((id) => {
                        filter.push(["hakId", "=", id]);
                        filter.push("or");
                    });
                    filter.pop();
                    this.filterValue = filter;
                } else {
                    // 2) Apply stored filters
                    this.filterValue = this.storeFilter;
                }
            } catch (error) {
                this.filterValue = null;
            }
        },
        getLwlString(cellInfo) {
            const formattedString = this.formatCapitalizedString(cellInfo);

            if (formattedString === "Betriebsbereit") {
                return "Ja";
            }
            return formattedString;
        },
        loadDataGrid() {
            // create data store
            this.haksDataSource = createStore(
                gridService.getStoreConfig({
                    key: "hakId",
                    loadUrl: rolloutviewApi.getHaksForGridUrl(),
                    loadParams: {
                        requireTotalCount: true,
                        requireGroupCount: true,
                    },
                })
            );
        },
        // handle the selection changed event of the datagrid
        onSelectionChanged() {
            this.calculatetSelectedItems();
        },
        convertToJaNeinTeilweise(cellInfo) {
            if (cellInfo.value === 0) {
                return "Nein";
            } else if (cellInfo.value === 1) {
                return "Teilweise";
            } else if (cellInfo.value === 2) {
                return "Ja";
            } else {
                return "HA nicht in IS-E";
            }
        },
        formatCapitalizedString(cellInfo) {
            if (cellInfo.value === "NEIN" || cellInfo.value === "JA") {
                return cellInfo.value.charAt(0).toUpperCase() + cellInfo.value.slice(1).toLowerCase();
            }
            return cellInfo.value;
        },
        getNetzgebietFromTp(dataRow) {
            //extracts the netzgebiet identifier from the tp
            if (dataRow.tp) {
                return dataRow.tp.split("-")[1];
            }
        },
        getTypFromAmplifyingNode(dataRow) {
            //extracts the AmplifyingNode Typ identifier from the tp
            if (dataRow && dataRow.nextAmplifyingNode && typeof dataRow.nextAmplifyingNode === "string") {
                const parts = dataRow.nextAmplifyingNode.split("-"); // REN-PR-TS10-0059 --> REN, PR, TS10, 0059
                if (parts.length > 2) {
                    const match = parts[2].match(/[a-zA-Z]{2}/); // TS10 --> TS
                    if (match) {
                        return match[0];
                    }
                }
            }
            return null;
        },
        getEstimatedInstallationTimeHours(cellInfo) {
            // transform seconds to hh:mm format
            if (cellInfo.value) {
                let hours = Math.floor(cellInfo.value / 3600);
                let minutes = Math.floor((cellInfo.value % 3600) / 60);

                minutes = minutes < 10 ? `0${minutes}` : minutes;
                hours = hours < 10 ? `0${hours}` : hours;
                return `${hours}:${minutes}`;
            }
        },
        getStatusVariant(status) {
            switch (status) {
                case constants.installationPlanStatusAmpel.RED:
                    return "text-danger";
                case constants.installationPlanStatusAmpel.YELLOW:
                    return "text-warning";
                case constants.installationPlanStatusAmpel.GREEN:
                    return "text-success";
            }
        },
    },
};
</script>

<style lang="scss">
.grid-container {
    width: 100%;
    height: 100%;
}

.drawer-container {
    width: 100%;
    height: calc(100vh - 92px) !important;
}

.date-picker-control {
    width: 380px;
    height: 38px;
}

.select-control {
    max-width: 250px;
}

// fix for calendar view: align weekday names and optimize calendar view
.dx-calendar-views-wrapper {
    > .dx-widget {
        padding-left: 10px;
        padding-right: 10px;
    }
    th {
        text-align: center;
    }
}
.dx-texteditor-input {
    font-size: 1rem;
}
.toolbar-separator {
    width: 10px;
}
</style>
