import Vue from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/dx.generic.retasks-scheme.css";

import { formatDate } from "@/filters/formatDate";
import { translateAuftragStatus } from "@/filters/translateAuftragStatus";

Vue.config.productionTip = false;
//import "./registerServiceWorker"; // register the service worker

// mirage mock API (gae: re-enabled 20.04.2022)
// to use it you must add these two libs to package.json
// - "faker": "^5.5.3"
// - "miragejs": "^0.1.47"
// import createServer from "./server";
// if (process.env.VUE_APP_MOCK_API === "1") {
//     // display info in case of API mocking enabled
//     if (process.env.VUE_APP_ENV_NAME !== "development") {
//         console.log(`Dont mock API in env ${process.env.VUE_APP_ENV_NAME}`, process.env);
//     } else {
//         console.warn("MirageJS mock API enabled!");
//         // create mock API server (only in localhost env)
//         createServer();
//     }
// }
// bootstrap
import {
    AlertPlugin,
    BootstrapVue,
    ButtonPlugin,
    CardPlugin,
    IconsPlugin,
    ModalPlugin,
    NavbarPlugin,
    SpinnerPlugin,
    TablePlugin,
} from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/app.css";
/* Customize Boostrap components configuration:
 * - all b-form-datepicker starts the week on Monday
 * */
Vue.use(BootstrapVue, { BFormDatepicker: { startWeekday: 1 } });
Vue.use(AlertPlugin);
Vue.use(ButtonPlugin);
Vue.use(CardPlugin);
Vue.use(IconsPlugin);
Vue.use(ModalPlugin);
Vue.use(NavbarPlugin);
Vue.use(SpinnerPlugin);
Vue.use(TablePlugin);

// fontawesome - Import all fas - See Gotcha 2 in: https://medium.com/front-end-weekly/how-to-use-fon-awesome-5-on-vuejs-project-ff0f28310821
import { dom, library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
Vue.component("font-awesome-icon", FontAwesomeIcon);
library.add(fas);
dom.watch(); // this will kick off the initial replacement of i to svg tags and configure a MutationObserver

import Box from "@/components/general/Box";
import BoxBody from "@/components/general/BoxBody";
import HorizontalSeparator from "@/components/general/HorizontalSeparator";
import MainComponent from "@/components/general/Main";

Vue.component("Box", Box);
Vue.component("BoxBody", BoxBody);
Vue.component("HorizontalSeparator", HorizontalSeparator);
Vue.component("MainComponent", MainComponent);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");

Vue.filter("formatDate", formatDate);
Vue.filter("translateAuftragStatus", translateAuftragStatus);

// DevExpress localization for DE
import deMessages from "devextreme/localization/messages/de.json";
import { locale, loadMessages } from "devextreme/localization";
loadMessages(deMessages);
locale("de");
